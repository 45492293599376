<script>
import { api } from "@/state/services";
import { VueSvgGauge } from 'vue-svg-gauge'

export default {
  components: {
    VueSvgGauge
  },
  data() {
    return {
      points: 0,
      graduations: null,
      previous: {
        id: null,
        name: '',
        points: 0,
        image: null,
      },
      next: {
        id: null,
        name: '',
        points: 0,
        image: null
      },
      progress: 0
    };
  },
  methods: {
    getGraduation() {
      api
        .get('report/graduation')
        .then(response => {
          if (response.data.status=='success') {
            this.graduations = response.data.list
            this.points = response.data.graduation.points
            this.previous = response.data.graduation.previous
            this.next = response.data.graduation.next
            this.progress = this.points - this.previous.points
          }
        })
    },
    nextGraduation(graduation) {
      const next = (parseInt(this.next.id) + 1)

      graduation.forEach((element) => {
        if (next == element.id) {
          this.next.id = element.id
          this.next.image = element.image
          this.next.name = element.name
          this.next.points = element.points
        }
      });
    },

    previousGraduation(graduation) {
      const next = (parseInt(this.next.id) - 1)

      graduation.forEach((element) => {
        if (next == element.id) {
          this.next.id = element.id
          this.next.image = element.image
          this.next.name = element.name
          this.next.points = element.points
        }
      });
    }
  },
  created () {
    this.getGraduation();
  }
};
</script>

<template>
  <div>
    <div style="max-width:350px;" class="m-auto">
      <vue-svg-gauge
        class="px-3"
        :start-angle="-110"
        :end-angle="110"
        :value="points - previous.points"
        :separator-step="0"
        :min="Number(0)"
        :max="Number(next.points - previous.points)"
        gauge-color="#EC3337"
        :scale-interval="0"
      />
    </div>
    <div class="report text-center">
      <h2>{{ points | points }}</h2>
      <p class="text-uppercase font-size-14 label">Pontos<br>Aproveitados</p>
      <div class="d-flex justify-content-around mt-4">
        <div>
          <span class="text-uppercase font-size-12">Atual</span>
          <div class="d-block">
            <img v-if="previous.image" :src="require('@/assets/images/' + previous.image)" class="rounded-circle bg-white" width="80" />
            <img v-else src="@/assets/images/logo-omni-icon.png" class="rounded-circle bg-white" width="80" />
          </div>
          <h3 class="m-0">{{ previous.points | points }}</h3>
          <p class="m-0">{{ previous.name }} </p>
        </div>
        <div>
          <i v-if="next.id != 2" class="bx bx-left-arrow pl-3 position-absolute h3" style="left: 0" @click="previousGraduation(graduations)"></i>
          <span class="text-uppercase font-size-12">Próximo</span>
          <i  v-if="next.id != 8" class="bx bx-right-arrow pl-3 position-absolute h3" style="right: 15px" @click="nextGraduation(graduations)"></i>
          <div class="d-block">
            <img v-if="next.image" :src="require('@/assets/images/' + next.image)" class="rounded-circle bg-white" width="80" />
            <img v-else src="@/assets/images/logo-omni-icon.png" class="rounded-circle bg-white" width="80" />
          </div>
          <h3 class="m-0">{{ next.points | points }}</h3>
          <p class="m-0">{{ next.name }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.report {
margin-top: -90px;
}
.label {
  line-height: 13px;
}
</style>