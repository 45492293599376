var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('VueTradingView',{attrs:{"options":{
    width: '100%',
    height: 500,
    symbol: 'NASDAQ:AAPL',
    theme: 'light',
    interval: 'D',
    timezone: 'exchange',
    theme: 'light',
    withdateranges: true,
    hide_side_toolbar: true,
    allow_symbol_change: true,
    save_image: false,
    show_popup_button: false,
    locale: 'br'
  }}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }