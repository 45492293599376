<template>
  <div ref="tradingviewticker" :id="container" class="tradingview-widget-container">
    <div class="tradingview-widget-container__widget"></div>
  </div>
</template>

<script>
const SCRIPT_ID = 'tradingview-widget-script';
const CONTAINER_ID = 'tradingview-widget-container';
export default {
  data() {
    return {
      container: CONTAINER_ID
    }
  },
  props: {
    options: {
      type: Object,
      default: () => ({
        symbols: [
        {
          "proName": "INDEX:IBOV",
          "title": "IBOV"
        },
        {
          "proName": "FX_IDC:USDBRL",
          "title": "USD/BRL"
        },
        {
          "proName": "BITSTAMP:BTCUSD",
          "title": "BTC/USD"
        },
        {
          "proName": "BITSTAMP:ETHUSD",
          "title": "ETH/USD"
        },
        {
          "description": "DOGE/USD",
          "proName": "BINANCE:DOGEPERP"
        }
        ],
        showSymbolLogo: true,
        colorTheme: "light",
        isTransparent: true,
        displayMode: "adaptive",
        locale: "br",
      })
    }
  },
  methods: {
    canUseDOM() {
      return typeof window !== 'undefined' && window.document && window.document.createElement;
    },
    appendScript(onload) {
      if (!this.canUseDOM()) {
        onload();
        return;
      }

      const script = document.createElement('script');
      script.id = SCRIPT_ID;
      script.type = 'text/javascript';
      script.async = true;
      script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-tickers.js';
      script.onload = onload;
      script.textContent = JSON.stringify(this.options)

      this.$refs.tradingviewticker.appendChild(script);
    },
  },
  mounted() {
    setTimeout(() => {
      this.appendScript()
    }, 0)
  },
}
</script>