<script>
import { api } from "@/state/services";

export default {
  data() {
    return {
      slide: 0,
      sliding: null,
      banner: null,
    }
  },
  methods: {
    onSlideStart() {
      this.sliding = true
    },
    onSlideEnd() {
      this.sliding = false
    },
    getBanner() {
      api
      .get('banner')
      .then((response) => {
        this.banner = response.data.list
      })
    }
  },
  mounted() {
    this.getBanner()
  },
  computed: {
    monitorWidth: function() {
      var width = screen.width;
      return width;
    }
  }
}
</script>

<template>
  <div id="banner" v-if="banner && banner.length > 0" class="mb-4">
    <b-carousel
      id="carousel-1"
      v-model="slide"
      :interval="4000"
      controls
      indicators
      background="#1c1a22"
      img-height="350"
      style="text-shadow: 1px 1px 2px #333;"
      @sliding-start="onSlideStart"
      @sliding-end="onSlideEnd"
    >
      <b-carousel-slide v-for="bann in banner" :key="bann.index">
        <template v-slot:img>
          <div v-if="monitorWidth < 700">
            <img
              :src="bann.mobile"
              :alt="bann.title"
              height="350"
            >
          </div>
          <div v-else>
            <img
              :src="bann.desktop"
              :alt="bann.title"
              height="350"
            >
          </div>
        </template>
      </b-carousel-slide>
    </b-carousel>
  </div>
</template>

<style scoped>
#banner {
  text-align: center;
}
</style>